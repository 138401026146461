var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "word-cloud",
      class: { "word-cloud--enlarged": _vm.enlargedUI },
    },
    [
      _c(
        "ModeSlideTransition",
        [
          _vm.isExplainMode
            ? _c(
                "PlayLayout",
                [
                  _c("ResizableText", {
                    staticClass: "word-cloud__text",
                    attrs: { message: _vm.mission.instructions },
                  }),
                ],
                1
              )
            : _vm._e(),
          [_vm.Mode.Play, _vm.Mode.Results].includes(_vm.mode)
            ? _c(
                "PlayLayout",
                {
                  scopedSlots: _vm._u(
                    [
                      !_vm.isPresenter
                        ? {
                            key: "left",
                            fn: function () {
                              return [
                                _c(
                                  "SlideFadeTransition",
                                  [
                                    _vm.isPlayMode
                                      ? _c("ResizableText", {
                                          staticClass:
                                            "word-cloud__text mb-4 mx-2",
                                          attrs: {
                                            message: _vm.mission.instructions,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.isResultsMode
                                      ? _c(
                                          "ul",
                                          { staticClass: "word-cloud__list" },
                                          _vm._l(
                                            _vm.answers,
                                            function (answer) {
                                              return _c(
                                                "li",
                                                { key: answer.word },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        answer.word +
                                                          " x" +
                                                          answer.number
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "SlideFadeTransition",
                                  [
                                    _vm.isPlayMode
                                      ? _c("TextField", {
                                          ref: "textField",
                                          staticClass: "word-cloud__field",
                                          attrs: {
                                            size: "sm",
                                            placeholder: "Type here ...",
                                            show: "show",
                                            status: "active",
                                            allowFreeSubmit: "",
                                            buttonText: _vm.locked
                                              ? _vm.timer
                                              : "Submit",
                                            disabled: _vm.locked,
                                            maxTextLength: _vm.maxTextLength,
                                          },
                                          on: { onSubmit: _vm.onSubmit },
                                        })
                                      : _vm._e(),
                                    _vm.isResultsMode
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "word-cloud__download",
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "RtbButton",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  size: "sm",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.downloadText,
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "currentColor",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " article "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " download "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2103540752
                                                ),
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "bottom" },
                                                  [_vm._v("Download text")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "RtbButton",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  size: "sm",
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.downloadImage,
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "currentColor",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " image"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " download "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  464228361
                                                ),
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "bottom" },
                                                  [_vm._v("Download image")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.isPlayMode
                                  ? _c("div", {
                                      staticClass: "word-cloud__overlay",
                                      class: {
                                        "word-cloud__overlay--visible":
                                          _vm.locked,
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "div",
                    { staticClass: "word-cloud__canvas" },
                    [
                      _c("canvas", {
                        ref: "canvas",
                        attrs: {
                          id: "word-cloud",
                          width: _vm.canvasWidth,
                          height: _vm.canvasHeight,
                        },
                      }),
                      _c("ResizeObserver", {
                        on: { notify: _vm.handleResize },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }