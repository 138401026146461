































































































import {
  defineComponent,
  watch,
  ref,
  nextTick,
  computed,
  onBeforeUnmount
} from "@vue/composition-api"
import WordCloud from "wordcloud"
import { ResizeObserver } from "vue-resize"
import { debounce, throttle } from "lodash"

import File from "@/services/file.service"

import { RtbButton } from "@/components/ui"
import ModeSlideTransition from "@/components/GroupTeams/Common/GameModes/ModeSlideTransition.vue"
import SlideFadeTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideFadeTransition.vue"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"

import PlayLayout from "@/components/GroupTeams/Common/GameModes/PlayLayout.vue"
import TextField from "@/components/GroupTeams/Common/Games/TextField.vue"

import User from "@shared/User"
import { Mode } from "@shared/enums"
import useStore from "@/use/useStore"
import useWordCloud from "./useWordCloud"
import useEnlargedUI from "@/use/useEnlargedUI"

const PIXEL_RATIO = 2
const FONT_SIZE = 16

export default defineComponent({
  name: "WordCloud",
  components: {
    RtbButton,
    SlideFadeTransition,
    ModeSlideTransition,
    PlayLayout,
    TextField,
    ResizableText,
    ResizeObserver
  },
  setup() {
    const {
      textField,
      timer,
      locked,
      maxTextLength,
      onSubmit,
      mission,
      viewer,
      mode,
      isPlayMode,
      isExplainMode,
      isResultsMode,
      answers
    } = useWordCloud()

    const { enlargedUI } = useEnlargedUI()

    const { store } = useStore()

    const isPresenter = computed(() => User.isPresenter(viewer.value))

    const words = computed(() =>
      answers.value.map(({ word, number }) => [
        word,
        FONT_SIZE * PIXEL_RATIO * number
      ])
    )

    const canvas = ref(null)

    const canvasWidth = ref(400)
    const canvasHeight = ref(200)

    const cloudSettings = ref({
      color: "#fff",
      fontWeight: "bold",
      fontFamily: "Sofia Pro",
      shrinkToFit: true,
      backgroundColor: "#292932",
      rotateRatio: 0.5,
      shuffle: true,
      rotationSteps: 2,
      minSize: 6,
      list: words
    })

    watch(
      words,
      throttle(() => {
        if (words?.value?.length && canvas.value) {
          WordCloud(canvas.value, cloudSettings.value)
        }
      }, 1000),
      { immediate: true }
    )

    watch(
      canvas,
      value => {
        if (value) {
          setCanvasSize(value.offsetWidth, value.offsetHeight)

          nextTick(() => {
            WordCloud(value, cloudSettings.value)
          })
        }
      },
      { immediate: true }
    )

    const handleResize = debounce(function ({ width, height }) {
      setCanvasSize(width, height)

      nextTick(() => {
        WordCloud(canvas.value, cloudSettings.value)
      })
    }, 500)

    function setCanvasSize(w, h) {
      canvasWidth.value = w * PIXEL_RATIO
      canvasHeight.value = h * PIXEL_RATIO
    }

    async function downloadImage() {
      File.download(
        await (await fetch(canvas.value.toDataURL())).blob(),
        "wordcloud.jpg"
      )
    }

    async function downloadText() {
      File.download(
        new Blob(
          [
            answers.value
              .map(answer => `${answer.word} x${answer.number}`)
              .join("\r\n")
          ],
          {
            type: "text/plain;charset=utf-8;"
          }
        ),
        "wordcloud.txt"
      )
    }

    return {
      enlargedUI,
      isPlayMode,
      isExplainMode,
      isResultsMode,
      isPresenter,
      textField,
      timer,
      locked,
      maxTextLength,
      answers,
      downloadImage,
      downloadText,
      canvas,
      mode,
      Mode,
      mission,
      onSubmit,
      handleResize,
      canvasWidth,
      canvasHeight
    }
  }
})
